import Character from "../model/character";
import {apiKey, MAPLE_API_BASE_URL} from "../../res/consts/common";
import {child, get, getDatabase, ref, set} from "firebase/database";
import {UnionsMap} from "../../App";
import {worldName} from "../../res/consts/world";
import {getLatestDateParam} from "../../utils/common";
import {getNexon} from "../../data/data_source/api_service/nexon_api_service";
import {GuildIdDto, GuildInfoDto} from "../model/dto/guild";
import {getCharacterInfo} from "./character_repository";

interface GetCachedGuildMembersHashParams {
  guildName: string;
  worldName: string;
}

export async function getCachedGuildMembersHash({
                                                  guildName,
                                                  worldName,
                                                }: GetCachedGuildMembersHashParams): Promise<number | null> {
  const dbRef = ref(getDatabase());
  const path = `guilds/${worldName}_${guildName}/members/hash`;

  try {
    const snapshot = await get(child(dbRef, path));
    const data = snapshot.val();
    return data;

  } catch (e) {
    console.error(e);
    return null;
  }
}

interface GetCachedGuildUnionsParams {
  guildName: string;
  worldName: string;
}

export async function getCachedGuildUnions({
                                             guildName,
                                             worldName,
                                           }: GetCachedGuildUnionsParams): Promise<UnionsMap | null> {
  const dbRef = ref(getDatabase());
  const path = `guilds/${worldName}_${guildName}/members/unions`;

  try {
    const snapshot = await get(child(dbRef, path));
    const data: UnionParams = snapshot.val();
    const unions: UnionsMap = new Map();
    for (const mainCharacter in data) {
      const characters = data[mainCharacter];
      unions.set(mainCharacter, characters.map(e => new Character({
        name: e["name"],
        guildName: e["guild-name"],
        imageUrl: e["img"],
        level: e["level"],
      })));
    }
    return unions;

  } catch (e) {
    console.error(e);
    return null;
  }
}

interface SaveGuildUnionsParams {
  guildName: string;
  worldName: string;
  hash: number;
  unions: UnionsMap;
}

interface UnionParams {
  [keyName: string]: { name: string, "guild-name"?: string, img?: string, level?: number }[];
}

export async function saveGuildUnions({guildName, worldName, hash, unions}: SaveGuildUnionsParams): Promise<void> {
  const db = getDatabase();
  const path = `guilds/${worldName}_${guildName}/members`;
  const membersSave: {
    unions: UnionParams;
    hash: number;
  } = {"hash": hash, "unions": {}};
  for (const [mainCharacter, characters] of unions) {
    membersSave["unions"][mainCharacter] = characters.map(c => ({
      "name": c.name,
      "guild-name": c.guildName,
      "img": c.imageUrl,
      "level": c.level,
    }));
  }

  await set(ref(db, path), membersSave);
}

interface GetLatestGuildMembersParams {
  guild: string;
  world: worldName;
  fullInfo?: boolean;
}

/**
 * 길드에 속한 캐릭터를 모두 가져옴.
 *
 * 각 캐릭터에 대해 캐릭터 정보 조회 API 요청을 날릴 경우 JS 런타임에서는 요청 한 번 당
 * 120ms 내외가 소모되므로 200명 내외의 캐릭터 정보를 조회하기엔 시간이 너무 오래 걸려
 * 크롤링과 API 요청을 섞어 사용.
 *
 * @param guild
 * @param world
 * @param fullInfo true일 경우 모든 캐릭터 정보가 담긴 Character[],
 *                 false일 경우 캐릭터명, 길드명, 월드명만 담긴 Character[] 반환
 */
export async function getLatestGuildMembers(
    {guild, world, fullInfo}: GetLatestGuildMembersParams): Promise<Character[] | null> {
  fullInfo ??= true;

  try {
    const id = await getGuildId({guild, world});
    if (id === null) {
      throw new Error("Fetching guild id failed");
    }

    const res = await getNexon<GuildInfoDto>({
      url: "/guild/basic",
      query: {
        oguild_id: id,
      },
    });
    if (!fullInfo) {
      return res.guild_member.map((name) => new Character({name, guildName: guild, worldName: world}));
    }
    return await Promise.all(res.guild_member.map(async (name) => {
      const info = await getCharacterInfo({characterName: name});
      return info ?
          new Character({
            name,
            guildName: guild,
            worldName: world,
            imageUrl: info.imageUrl,
            level: info.level,
            className: info.className,
          }) :
          new Character({
            name,
            guildName: guild,
            worldName: world,
          });
    }));
  } catch (e) {
    return null;
  }
}

interface GetGuildIdParams {
  guild: string;
  world: worldName;
}

export async function getGuildId({guild, world}: GetGuildIdParams): Promise<string | null> {
  try {
    const res = await getNexon<GuildIdDto>({
      url: "/guild/id",
      query: {
        guild_name: guild,
        world_name: world,
      },
    });
    return res.oguild_id;
  } catch (e) {
    return null;
  }
}

/*
export async function getLatestGuildMembersOld({
                                                 guildName,
                                                 worldName
                                               }: GetLatestGuildMembersParams): Promise<Character[] | null> {
  try {
    let res: Response;
    let guildId: string;
    let guildMembers: string[];

    res = await fetch(`${MAPLE_API_BASE_URL}/guild/id` +
      `?guild_name=${encodeURIComponent(guildName)}` +
      `&world_name=${encodeURIComponent(worldName)}`, {
      headers: {
        "accept": "application/json",
        "x-nxopen-api-key": apiKey,
      }
    });
    if (res.status !== 200) {
      throw new Error("Error while fetching guild id");
    }
    guildId = (await res.json())["oguild_id"];

    res = await fetch(`${MAPLE_API_BASE_URL}/guild/basic?oguild_id=${guildId}`, {
      headers: {
        "accept": "application/json",
        "x-nxopen-api-key": apiKey,
      }
    });
    if (res.status !== 200) {
      throw new Error("Error while fetching guild info");
    }

    guildMembers = (await res.json())["guild_member"];

    return guildMembers.map((characterName) => new Character({name: characterName, guildName: guildName}));

  } catch (e) {
    console.error(e);
    return null;
  }
}*/

export async function getGuildWorlds(guild: string): Promise<worldName[] | null> {
  try {
    let res: Response;

    res = await fetch(`${MAPLE_API_BASE_URL}/ranking/guild` +
        `?date=${getLatestDateParam()}` +
        `&guild_name=${encodeURIComponent(guild)}` +
        `&ranking_type=0`, {
      headers: {
        "accept": "application/json",
        "x-nxopen-api-key": apiKey,
      },
    });
    if (res.status !== 200) {
      try {
        console.log((await res.json()));
      } catch (e) {
      }
      throw new Error("Error while fetching guild id");
    }

    return (await res.json())["ranking"].map((o: Record<string, string | number>) => o["world_name"]);

  } catch (e) {
    console.error(e);
    return null;
  }
}

