import Character from "../model/character";
import {apiKey, MAPLE_API_BASE_URL} from "../../res/consts/common";
import {getLatestDateParam} from "../../utils/common";
import {getNexon} from "../../data/data_source/api_service/nexon_api_service";
import {ResponseJson} from "../model/response_json";
import {CharacterIdDto, CharacterInfoDto} from "../model/dto/character";
import {crawlNexon} from "../../data/data_source/api_service/nexon_crawl_service";
import {isNode} from "browser-or-node";

if (isNode) {
  const {TextDecoder, TextEncoder} = eval("require")("util");
  const {ReadableStream, TransformStream} = eval("require")("stream/web");

  Object.assign(global, {
    TextDecoder, TextEncoder, ReadableStream, TransformStream,
    // jest에서 fetch를 사용하는 코드 동작 시 필요
    fetch: require("isomorphic-fetch"),
  });
}

// import cheerio from "cheerio";
const cheerio = require("cheerio");

interface GetCharacterInfoParams {
  characterName: string;
}

export async function getCharacterInfo(
    {characterName}: GetCharacterInfoParams,
): Promise<Character | null> {
  try {
    const ocid = await getCharacterId({characterName});
    if (!ocid) {
      throw new Error("Fetching ocid failed");
    }

    let res!: CharacterInfoDto;
    const maxTries = 7;
    for (let i = 0; i < maxTries; i++) {
      try {
        res = await getNexon<CharacterInfoDto>({
          url: "/character/basic",
          query: {
            ocid: ocid,
            date: getLatestDateParam(-i),
          },
        });
        break;
      } catch (e) {
        if (i === maxTries - 1) {
          throw e;
        }
      }
    }
    return new Character({
      name: res.character_name,
      guildName: res.character_guild_name,
      worldName: res.world_name,
      imageUrl: res.character_image,
      level: res.character_level,
      className: res.character_class,
    });
  } catch (e) {
    return null;
  }
}

interface GetCharacterIdParams {
  characterName: string;
}

export async function getCharacterId(
    {characterName}: GetCharacterIdParams): Promise<string | null> {
  try {
    const res = await getNexon<CharacterIdDto>({
      url: "/id",
      query: {
        "character_name": characterName,
      },
    });
    return res.ocid;
  } catch (e) {
    return null;
  }
}

/*
export async function getCharacterInfoOld(
  {characterName}: GetCharacterInfoParams): Promise<Character | null> {
  try {
    let res: Response;
    let success = false;
    const maxTries = 7;

    for (let i = 0; i < maxTries; i++) {
      res = await fetch(`${MAPLE_API_BASE_URL}/character/basic` +
        `?date=${getLatestDateParam(i)}` +
        `&ocid=${await getCharacterId(characterName)}`, {
        headers: {
          "accept": "application/json",
          "x-nxopen-api-key": apiKey,
        }
      });
      if (res.status === 200) {
        success = true;
        break;
      } else {
        try {
          console.log((await res.json()));
        } catch (e) {
        }
        if (i < maxTries - 1) {
          console.log(`Error on try ${i}, retrying...`);
          continue;
        } else {
          console.log(`Error on try ${i}, quitting...`);
        }
      }
    }

    if (!success) {
      throw new Error("Error while fetching character info");
    }

    const o = await res!.json() as CharacterInfoDto;
    return new Character({
      name: o.character_name,
      guildName: o.character_guild_name,
      imageUrl: o.character_image,
      level: o.character_level,
      className: o.character_class,
    });
  } catch (e) {
    console.error(e);
    return null;
  }
}
*/

interface GetMainCharacterNameParams {
  characterName: string;
  worldName: string;
}

interface GetMainCharacterNameResponse {
  mainCharacter: string | null;
  waitMs: number | null;
}

/**
 * @deprecated
 * TODO: why?? 최신 스타일로 다시 써야 해서 그런가?
 */
export async function getMainCharacterName({
                                             characterName,
                                             worldName,
                                           }: GetMainCharacterNameParams): Promise<GetMainCharacterNameResponse> {
  try {
    // const cached: string | null = sessionStorage.getItem(characterName); // TODO: 메인캐릭터명 캐싱하지 않기
    const cached = null;

    if (cached !== null) {
      return {mainCharacter: cached, waitMs: null};
    }
    const charId = await getCharacterId({characterName});
    if (!charId) {
      throw new Error("Error while fetching character id");
    }

    const dateParam = getLatestDateParam();
    const res: Response = await fetch(`${MAPLE_API_BASE_URL}/ranking/union?date=${dateParam}&world_name=${encodeURIComponent(worldName)}&ocid=${charId}`, {
      headers: {
        "accept": "application/json",
        "x-nxopen-api-key": apiKey,
      },
    });
    if (res.status !== 200) {
      throw new Error("Error while fetching union ranking");
    }
    const json: any = await res.json();
    const mainCharacterName: string | null = json["ranking"][0]?.["character_name"] ?? null;
    if (mainCharacterName) {
      // sessionStorage.setItem(characterName, mainCharacterName);
    }
    return {mainCharacter: mainCharacterName, waitMs: 10};

  } catch (e) {
    throw e;
  }
}

interface CrawlChracterImageParams {
  characterName: string;
}

export async function crawlCharacterImage({characterName}: CrawlChracterImageParams): Promise<string | undefined> {
  const html = await crawlNexon({
    url: `/N23Ranking/World/Total`,
    query: {
      c: characterName,
      w: 0, // 전체 월드 대상 검색
    },
  });
  const $ = cheerio.load(html);
  const element = $(".search_com_chk > .left > .char_img > img:first-of-type");
  const src = element.attr("src");
  return src;
}

export async function getCharacterIdOld(characterName: string): Promise<string> {
  const res: Response = await fetch(`${MAPLE_API_BASE_URL}/id?character_name=${encodeURIComponent(characterName)}`, {
    headers: {
      "accept": "application/json",
      "x-nxopen-api-key": apiKey,
    },
  });
  if (res.status !== 200) {
    throw new Error(`Error while fetching character id for ${characterName}`);
  }
  const json: any = await res.json();
  return json["ocid"];
}

export function getUnionCharacters(characterName: string): Character[] {
  let chars: string[];
  if (characterName === "06월18일휴무") {
    chars = ["06월18일휴무", "19720801", "4po포", "강호겸", "개코코맘"];
  } else if (characterName === "알파문월") {
    chars = ["알파문월", "AlphaDemonx", "알파힐러", "알파Z로", "AlphaHero"];
  } else if (characterName === "님혹시은월") {
    chars = ["님혹시은월", "복치", "복치델라", "복치파인더"];
  } else if (characterName === "무광데몽") {
    chars = ["무광데몽", "무광박쥐"];
  } else {
    chars = [characterName];
  }
  return chars.map((c: string) => new Character({name: c, guildName: "뽁치"}));
}