import RateLimiter from "../../../utils/rate_limiter";

const rateLimiter = new RateLimiter({interval: 5});

export async function crawlNexon({url, query}: {
  url: string,
  query?: Record<string, string | number>,
}): Promise<string> {
  try {
    // @ts-ignore
    const paramsStr = new URLSearchParams(query).toString();
    const destUrl = `https://maplestory.nexon.com${url}${paramsStr ? `?${paramsStr}` : ""}`;
    const res = await rateLimiter.enqueue(() =>
      fetch(destUrl, {
        headers: {
          "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.0.0 Safari/537.36",
        }
      }));
    if (res.status !== 200) {
      throw new Error(`Error while getting url "${url}": ${JSON.stringify(await res.json())}`);
    }
    return await res.text();
  } catch (e) {
    throw e;
  } finally {
    // @ts-ignore
    // console.log(`took ${end - start}ms and ${localTries} tries to process ${url} with query: ${JSON.stringify(query)}`);
  }
}