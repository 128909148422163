import React, {useCallback} from "react";
import {useScrollTrigger, Zoom} from "@mui/material";
import {KeyboardArrowUp} from "@mui/icons-material";
import {IconButton} from "@mui/joy";

interface ScrollToTopOptions {
  threshold?: number;
}

export default function ScrollToTop(options: ScrollToTopOptions = {}): React.JSX.Element {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: options.threshold ?? 300,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  }, []);

  return <Zoom in={trigger}>
    <IconButton onClick={scrollToTop}
                variant="outlined"
                sx={{
                  backgroundColor: "white",
                  position: "fixed",
                  bottom: 32,
                  right: 32,
                  zIndex: 1,
                  boxShadow: "sm",
                }}>
      <KeyboardArrowUp/>
    </IconButton>
    {/*
    <Fab onClick={scrollToTop}
         disableRipple
         sx={{
           position: "fixed",
           bottom: 32,
           right: 32,
           zIndex: 1,
         }}
         color="default"
         size="small">
      <KeyboardArrowUp/>
    </Fab>*/}
  </Zoom>;
}