import React from "react";
import {worldToEn} from "../utils/common";
import {worldName} from "../res/consts/world";

function WorldIcon(props: { size?: string | number, world: worldName }): React.JSX.Element {
  const worldEn = worldToEn(props.world);
  const logo = require(`../res/image/world/${worldEn}.webp`);
  return <img className="unselectable"
              draggable={false}
              style={{
                objectFit: "fill",
                width: props.size,
                height: props.size,
              }}
              src={logo} alt={props.world}/>;
}

export default React.memo(WorldIcon);