import {
  getCachedGuildMembersHash,
  getCachedGuildUnions,
  getLatestGuildMembers,
  saveGuildUnions,
} from "../domain/repository/guild_repository";
import {getMembersHash, sleep} from "../utils/common";
import Character from "../domain/model/character";
import {getMainCharacterName} from "../domain/repository/character_repository";
import {UnionsMap} from "../App";
import {worldName} from "../res/consts/world";

interface GetGuildUnionsParams {
  guildName: string;
  worldName: worldName;
  coldLoad?: boolean;
  shouldContinue?: () => boolean;
  onFindUnion: (leftGuildMembers: Map<string, Character>, foundUnions: UnionsMap) => void;
  onDone: () => void;
}

export async function getGuildUnions({
                                       guildName,
                                       worldName,
                                       coldLoad = false,
                                       shouldContinue,
                                       onFindUnion,
                                       onDone,
                                     }: GetGuildUnionsParams): Promise<void> {
  const latestGuildMemberNames: Character[] = await getLatestGuildMembers({guild: guildName, world: worldName, fullInfo:false}) ?? [];
  let waitingMembersMap: Map<string, Character> = latestGuildMemberNames.reduce((map, member) => map.set(member.name, member), new Map());

  // Check if cache is up to date
  const latestHash: number = getMembersHash(latestGuildMemberNames.map(e=>e.name));
  const cachedHash: number | null = await getCachedGuildMembersHash({guildName, worldName});
  const isCacheUpToDate = latestHash === cachedHash;
  console.log(`cache is up to date: ${isCacheUpToDate}`);

  if (shouldContinue && !shouldContinue()) {
    return;
  }
  onFindUnion(waitingMembersMap, new Map());

  if (isCacheUpToDate && !coldLoad) {
    // DB에서 캐싱된 자료를 가져옴
    waitingMembersMap = new Map(waitingMembersMap);
    const cachedUnions: UnionsMap = await getCachedGuildUnions({guildName, worldName}) ?? new Map();
    for (const [mainCharacter, unions] of cachedUnions) {
      for (const character of unions) {
        waitingMembersMap.delete(character.name);
      }
    }
    if (shouldContinue && !shouldContinue()) {
      return;
    }
    onFindUnion(waitingMembersMap, cachedUnions);
  } else {
    // 각 캐릭터 별로 API로 데이터를 조회해 본캐와 부캐를 구별함
    const latestGuildMembers: Character[] = (await getLatestGuildMembers({guild: guildName, world: worldName})) ?? [];

    let foundUnions: UnionsMap = new Map();
    const promises: Promise<any>[] = [];
    for (const member of latestGuildMembers) {
      const promise = getMainCharacterName({
        characterName: member.name,
        worldName: worldName,
      }).then(({mainCharacter, waitMs}: { mainCharacter: string | null; waitMs: number | null }) => {
        if (mainCharacter) {
          waitingMembersMap = new Map(waitingMembersMap);
          foundUnions = new Map(foundUnions);
          // if (foundUnions.has(mainCharacter)) {
          //   foundUnions.get(mainCharacter)!.push(member);
          // } else {
          //   foundUnions.set(mainCharacter, [member]);
          // }
          // 캐릭터 리스트를 변수로 받는 Memoization된 컴포넌트가 제대로 업데이트 될 수 있도록
          // 매번 새 리스트를 만들어 반환
          foundUnions.set(mainCharacter, [...(foundUnions.get(mainCharacter) ?? []), member]);
          waitingMembersMap.delete(member.name);
          if (shouldContinue && !shouldContinue()) {
            return;
          }
          console.log(`processed ${member.name}`);
          onFindUnion(waitingMembersMap, foundUnions);
        }
      }).catch((e) => {
        console.log(`Error while calculating unions: ${e}`);
      })
      promises.push(promise);
      await sleep(5);
    }

    await Promise.allSettled(promises);

    console.log("saving unions");
    if (shouldContinue && !shouldContinue()) {
      return;
    }

    await saveGuildUnions({
      guildName,
      worldName,
      hash: latestHash,
      unions: foundUnions,
    });
  }

  if (shouldContinue && !shouldContinue()) {
    return;
  }
  onDone();
}