import hmm from "./res/image/hmm.webp";

export default function ErrorPage() {
  return <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    height: '30vh',
  }}>
    <img draggable={false} unselectable="on" style={{width: "60%", maxWidth: "256px"}} src={hmm}/>
  </div>;
}