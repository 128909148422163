import {Mutex} from "async-mutex";
import {type} from "node:os";

type InputFunction<T> = (...params: any[]) => T|Promise<T>;

export default class RateLimiter {
  #interval: number;
  #maxSize?: number;
  #waitForFinish?: boolean;
  #queue: [InputFunction<any>, any[], Function, Function][];
  #isProcessing: boolean;

  #mutex: Mutex;

  constructor({interval, maxSize, waitForFinish}: { interval: number; maxSize?: number, waitForFinish?:boolean, }) {
    if (interval < 1) {
      throw new Error("`interval` cannot be smaller than 1");
    }
    this.#interval = interval;
    if (typeof maxSize === "number" && maxSize < 1) {
      throw new Error("`maxSize` cannot be smaller than 1");
    }
    this.#maxSize = maxSize;
    this.#waitForFinish = waitForFinish ?? true;
    this.#queue = [];
    this.#isProcessing = false;
    this.#mutex = new Mutex();
  }

  async enqueue<T>(fn: InputFunction<T>, ...params: any[]): Promise<T> {
    return new Promise(async (resolve, reject) => {
      await this.#mutex.runExclusive(async () => {
        if (this.#maxSize && this.#maxSize <= this.#queue.length) {
          this.#queue.shift();
        }
        this.#queue.push([fn, params, resolve, reject]);
      });
      await this.#processQueue();
    });
  }

  async clear(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.#mutex.runExclusive(() => {
          this.#queue = [];
        });
        resolve();
      } catch(e) {
        reject(e);
      }
    })
  }

  async #processQueue() {
    if (this.#isProcessing) {
      return;
    }

    this.#isProcessing = true;

    let fn: InputFunction<any> | undefined,
      params: any[] | undefined,
      resolve: Function | undefined,
      reject: Function | undefined;

    await this.#mutex.runExclusive(async () => {
      [fn, params, resolve, reject] = this.#queue.shift() ?? [];
    }, 999);

    if (!fn) {
      this.#isProcessing = false;
      return;
    }

    params ??= [];

    if (this.#waitForFinish) {
      try {
        const result = await fn(...params);
        resolve?.(result);
      } catch (e) {
        reject?.(e);
      } finally {
        setTimeout(() => {
          this.#isProcessing = false;
          this.#processQueue();
        }, this.#interval);
      }
    } else {
      fn(...params).then((result:any)=>{
        resolve?.(result);
      }).catch((e:any)=>{
        reject?.(e);
      })
      setTimeout(() => {
        this.#isProcessing = false;
        this.#processQueue();
      }, this.#interval);
    }
  }
}
