import "./App.css";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Character from "./domain/model/character";
import {initializeFirebase} from "./firebase";
import {getGuildUnions} from "./service/character_service";
import {Masonry} from "@mui/lab";
import RateLimiter from "./utils/rate_limiter";
import {
  Box, Button,
  Card, Checkbox,
  CssVarsProvider, DialogActions, DialogContent, DialogTitle, Divider,
  extendTheme,
  IconButton,
  Input,
  LinearProgress,
  List, ListDivider,
  ListItem, Modal, ModalDialog,
  Theme, Tooltip,
  Typography,
} from "@mui/joy";
import ScrollToTop from "./components/scroll_to_top";
import {InfoOutlined, InfoRounded, RefreshRounded, SearchRounded, WarningRounded} from "@mui/icons-material";
import WorldIcon from "./components/world_icon";
import SearchGuildDialog from "./components/search_guild_dialog";
import {WORLD_NAMES, worldName} from "./res/consts/world";
import {useLocation, useSearchParams} from "react-router-dom";
import {unstable_batchedUpdates} from "react-dom";

// for material
// const theme = createTheme({
//   typography: {
//     fontFamily: 'Pretendard, sans-serif'
//   }
// })

declare module "@mui/joy/ModalDialog" {
  interface ModalDialogPropsLayoutOverrides {
    top: true;
  }
}

// for joy ui
const theme = extendTheme({
  fontFamily: {
    display: "Pretendard, sans-seif",
    body: "Pretendard, sans-seif",
    code: "Pretendard, sans-seif",
    fallback: "Pretendard, sans-seif",
  },

  // Modal dialog top 속성 추가
  components: {
    JoyModalDialog: {
      defaultProps: {layout: "top"},
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.layout === "top" && {
            // top: "12vh",
            top: "84px",
            left: "50%",
            transform: "translateX(-50%)",
          }),
        }),
      },
    },
  },
});

export type UnionsMap = Map<string, Character[]>;

interface curSearchInfo {
  guild?: string;
  world?: worldName;
}

function App() {
  const [isLoading, setIsLoading] = useState(false);
  const [waitingMembersMap, setWaitingMembersMap] = useState<Map<string, Character>>(new Map());
  const [doneMembersMap, setDoneMembersMap] = useState<UnionsMap>(new Map());
  const [filteredUnions, setFilteredUnions] = useState<UnionsMap>(new Map());
  const [filterText, setFilterText] = useState("");
  const [forceRefresh, setForceRefresh] = useState(false);
  const [showUnionOnly, setShowUnionOnly] = useState(false);
  // const [curSearchInfo, setCurSearchInfo] = useState<curSearchInfo>({
  //   guild: "뽁치",
  //   world: "스카니아",
  // });

  const [searchParams, setSearchParams] = useSearchParams();

  const guild = searchParams.get("g");
  const world = searchParams.get("w") as worldName;

  const onSearchGuild = useCallback((guild: string, world: worldName) => {
    setSearchParams({
      g: guild,
      w: world,
    });
  }, []);
  const onClickForceRefresh = useCallback(() => {
    // if (!guild || !world) {
    //   return;
    // }
    // load({guild, world, forceRefresh: true});
    setForceRefresh(true);
  }, []);
  const onCheckShowUnionOnly = useCallback((value: boolean) => {
    setShowUnionOnly(value);
  }, []);

  const filterThrottler = useMemo(() => new RateLimiter({interval: 50, maxSize: 1}), []);
  const filterRegEx = useMemo(() => new RegExp("[0-9A-Za-zㄱ-ㅎㅏ-ㅣ가-힣]", "g"), []);

  const onSearchChange = useCallback((e: any) => {
    filterThrottler.enqueue(() => {
      const filtered = e.target.value.match(filterRegEx)?.join("") ?? "";
      setFilterText(filtered);
    });
  }, []);

  const resetResults = useCallback(() => {
    setWaitingMembersMap(new Map());
    setDoneMembersMap(new Map());
    setFilteredUnions(new Map());
    setFilterText("");
  }, []);

  useEffect(() => {
    initializeFirebase();
  }, []);

  /*
  useEffect(() => {
    if (!guild || !world) {
      document.title = "길드 부캐 검색";
      return;
    }

    document.title = `${world} ${guild}`;
    load({guild, world});
  }, [guild, world]);
  */

  // 로딩 중에 페이지 벗어나는 것 방지
  //method 1
  // useEffect(() => {
  //   const handler = (event: Event) => {
  //     console.log(`inside handler: ${isLoading}`)
  //     if (isLoading) {
  //       event.preventDefault();
  //       event.returnValue = true;
  //     }
  //   }
  //   window.addEventListener('beforeunload', handler);
  //   return () => {
  //     window.removeEventListener('beforeunload', handler)
  //   };
  // }, [isLoading]);

  //method 2 not working
  // useEffect(() => {
  //   window.addEventListener('beforeunload', (e) => {
  //       console.log(`onBeforeUnload: isLoading: ${isLoading}`)
  //     if (isLoading) {
  //       e.preventDefault();
  //       e.returnValue = true;
  //     }
  //   });
  // }, []);

  // method 3
  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      setIsLoading(value => {
        if (value) {
          event.preventDefault();
          event.returnValue = "";
        }
        return value;
      });
    };
    window.addEventListener("beforeunload", handler);
    return () => {
      window.removeEventListener("beforeunload", handler);
    };
  }, []);

  // 길드, 월드가 설정되면 검색
  // const load = useCallback(({guild, world, shouldContinue = () => true, forceRefresh = false}: {
  //   guild: string,
  //   world: worldName,
  //   shouldContinue?: () => boolean,
  //   forceRefresh?: boolean,
  // }) => {
  useEffect(() => {
    if (!guild || !world) {
      document.title = "길드 부캐 검색";
      return;
    }

    document.title = `${world} ${guild}`;

    resetResults();
    setIsLoading(true);

    const updateThrottler = new RateLimiter({interval: 250, maxSize: 1});
    let shouldContinue = true;

    getGuildUnions({
      guildName: guild,
      worldName: world,
      coldLoad: forceRefresh,
      shouldContinue: () => shouldContinue,
      onFindUnion: (leftGuildMembers: Map<string, Character>, foundUnions: UnionsMap) => {
        if (shouldContinue) {
          updateThrottler.enqueue(() => {
            unstable_batchedUpdates(() => {
              setWaitingMembersMap(leftGuildMembers);
              setDoneMembersMap(foundUnions);
            });
          });
        }
      },
      onDone: () => {
        setIsLoading(false);
      },
    });

    return () => {
      updateThrottler.clear();
      shouldContinue = false;
    };
  }, [guild, world, forceRefresh]);

  useEffect(() => {
    if (!filterText.length) {
      setFilteredUnions(doneMembersMap);
    } else {
      const filteredMap = new Map(Array.from(doneMembersMap)
          .filter(([key, value]) => value.some(char => char.name.toLowerCase().includes(filterText.toLowerCase()))));
      setFilteredUnions(filteredMap);
    }
  }, [doneMembersMap, filterText]);

  /*

  width: 75%;
  min-width: 240px;
  max-width: 1440px;
   */
  return (<Box id={"app-root"}>
    <ScrollToTop/>
    <LinearProgress sx={{width: 1, display: isLoading ? "initial" : "none", position: "fixed", top: 0, zIndex: 1}}/>
    <CssVarsProvider theme={theme}>
      <Box sx={{
        width: 1,
        display: "flex",
        flexDirection: "column",
        paddingBottom: 2,
        maxWidth: "1200px",
        justifyContent: "center",
      }}>
        <Box className="body-container" sx={{
          display: "flex", flexDirection: "column", gap: 2, paddingX: {
            xs: 2, sm: 6,
          }, paddingBottom: 8, minWidth: "240px",
        }}>
          <GuildTitleSection guild={guild}
                             world={world}
                             showUnionOnly={showUnionOnly}
                             onSearchGuild={onSearchGuild}
                             onClickForceRefresh={onClickForceRefresh}
                             onCheckShowUnionOnly={onCheckShowUnionOnly}
          />
          {!guild || !world || !WORLD_NAMES.includes(world as worldName) ? (
              <></>
          ) : (<>
                <SearchCard onChange={onSearchChange}/>
                <StatusCard isLoading={isLoading}
                            waiting={[...waitingMembersMap.values()]}
                            done={[...doneMembersMap.values()].flat()}/>
                {/* width를 auto로 설정해야 오른쪽에 여백이 생기지 않음 */}
                <UnionMasonry filteredUnions={filteredUnions} showUnionOnly={showUnionOnly} searchText={filterText}/>
              </>
          )}
        </Box>
      </Box>
    </CssVarsProvider>
  </Box>);
}

const masonryTheme = extendTheme({
  ...theme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 360, // Galaxy S8+
      md: 650,
      lg: 900,
      xl: 1150,
    },
  },
});

const UnionMasonry = React.memo(({filteredUnions, showUnionOnly, searchText = ""}: {
  filteredUnions: UnionsMap,
  showUnionOnly: boolean,
  searchText?: string
}) => {
  // return <Masonry sx={{width: "auto"}} columns={{xs: 2, sm: 3, md: 4, lg: 5}} spacing={2}>

  let unions = Array.from(filteredUnions);
  if (showUnionOnly) {
    unions = unions.filter(e => e[1].length > 1);
  }

  return <CssVarsProvider theme={masonryTheme}> <Box sx={{
    columnCount: {xs: 1, sm: 2, md: 3, lg: 4},
    columnGap: 2,
    "& > *": {
      marginBottom: 2,
    },
  }}>
    {unions.map(e => <MaterialUnionCard key={e[0]} characters={e[1]} highlight={searchText}/>)}
  </Box></CssVarsProvider>;
});

const GuildTitleSection = React.memo((props: {
  guild?: string | null,
  world?: string | null,
  showUnionOnly: boolean,
  onSearchGuild: (guild: string, world: worldName) => void,
  onClickForceRefresh: () => void,
  onCheckShowUnionOnly: (value: boolean) => void,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return <Box sx={{
    display: "flex",
    flexDirection: {xs: "column", sm: "row"},
    alignItems: {xs: "flex-start", sm: "center"},
    justifyContent: "space-between",
    gap: 2,
    marginTop: 2,
  }}>
    <Box sx={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "start",
      gap: 1,
    }}>
      <WorldIcon size="24px" world={props.world as worldName}/>
      {props.guild ?
          <Typography level="h2">{props.guild}</Typography> :
          <Typography level="h3" color="neutral">길드를 검색해주세요.</Typography>
      }

      <Box sx={{display: "flex", flexDirection: "row"}}>
        <SearchGuildDialog onSelectGuild={props.onSearchGuild}>
          <IconButton size="sm">
            <SearchRounded/>
          </IconButton>
        </SearchGuildDialog>

        <IconButton size="sm" onClick={() => setDialogOpen(true)}>
          <RefreshRounded/>
        </IconButton>
      </Box>
    </Box>

    {props.guild ?
        <Checkbox
            sx={{marginLeft: 2, alignSelf: {xs: "flex-end", sm: "initial"}}}
            label="부캐 있는 캐릭터만 보기"
            onChange={(e) => props.onCheckShowUnionOnly(e.target.checked)}
        /> : <></>
    }

    <Modal open={dialogOpen} onClose={() => setDialogOpen(false)}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          새로고침
        </DialogTitle>
        <DialogContent>
          새로고침하여 최신 결과를 불러올까요? 길드원이 보이지 않거나 로딩이 되지 않을 때 사용해주세요.
        </DialogContent>
        <DialogActions>
          <Button variant="solid" color="primary" onClick={() => {
            setDialogOpen(false);
            props.onClickForceRefresh();
          }}>
            확인
          </Button>
          <Button variant="plain" color="neutral" onClick={() => setDialogOpen(false)}>
            취소
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  </Box>;
});

const SearchCard = React.memo(({onChange}: { onChange: (e: any) => any }) => {
  return <Card sx={{
    padding: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    top: (theme: Theme) => theme.spacing(2),
    marginBottom: (theme: Theme) => theme.spacing(2),
    zIndex: 1,
    boxShadow: "lg",
  }}>
    <Input id="search-field"
           variant="outlined"
           fullWidth
           placeholder="닉네임 검색"
           onChange={onChange}></Input>
  </Card>;
}, () => false);

const StatusCard = React.memo(({isLoading = false, waiting = [], done = []}: {
  isLoading: boolean,
  waiting: Character[],
  done: Character[],
}) => {
  if (!isLoading && !waiting.length) {
    return <></>;
  }

  return (<Card size="sm" sx={{typography: {xs: "body-sm", sm: "body-md"}}} className={"total-card"}>
    <Box sx={{display: "flex", flexDirection: "column", gap: 1, p: 2}}>
      {isLoading ? (<>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
          <InfoRounded/>
          <span>정보 조회
            중...{waiting.length + done.length > 0 ? `(${done.length}/${waiting.length + done.length})` : ""}</span>
        </Box>
      </>) : (<>
        <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 1}}>
          <WarningRounded/>
          <span>정보 조회 실패</span>
          <Tooltip title="오랫동안 접속하지 않은 경우 조회에 실패할 수 있어요."
                   arrow placement="top"
                   enterTouchDelay={0}
                   leaveTouchDelay={3000}>
            <InfoOutlined sx={{fontSize: 16}}/>
          </Tooltip>
        </Box>
        <Box sx={{display: "flex", flexWrap: "wrap", gap: 1}}>
          {waiting.map(char => (
              <a style={{all: "unset", cursor: "pointer"}}
                 href={`https://maple.gg/u/${char.name}`}
                 key={char.name}>
                <span>{char.name}</span>
              </a>
          ))}</Box>
      </>)}
    </Box>
  </Card>);
}, (prevProps, nextProps) => prevProps.isLoading === nextProps.isLoading
    && prevProps.waiting.length === nextProps.waiting.length
    && prevProps.done.length === nextProps.done.length);

interface MaterialUnionCardProps {
  characters?: Character[];
  highlight: string;
}

const MaterialUnionCard = React.memo(({characters = [], highlight = ""}: {
  characters: Character[],
  highlight: string,
}) => {
  if (!characters.length) {
    return <></>;
  }

  // border-box 없으면 card 자체의 padding으로 인해 Masonry 틀을 벗어남
  return <Card sx={{display: "inline-block", width: "100%", boxSizing: "border-box", padding: 0}} size="sm"
               variant="outlined">
    <List>
      {characters.map((char, index) => {
        let name;
        if (!highlight.length) {
          name = char.name;
        } else {
          const reg = new RegExp(highlight, "gi");
          name = char.name.replace(reg, "<mark>$&</mark>");
        }
        return <div key={name} style={{display: "contents"}}>
          <ListItem sx={{paddingX: 1, paddingY: 0, typography: {xs: "body-sm", md: "body-md"}}}>
            <Box sx={{
              width: {xs: "48px", md: "72px"},
              height: {xs: "64px", md: "72px"},
            }}>
              <img width="100%" height="100%" style={{objectFit: "cover"}} src={char.imageUrl}/>
            </Box>
            <Box sx={{display: "flex", flexDirection: "column"}}>
              <a style={{all: "unset", cursor: "pointer"}} href={`https://maple.gg/u/${char.name}`}><span
                  dangerouslySetInnerHTML={{__html: name}}/></a>
              {char.level ?
                  <Typography sx={{typography: {xs: "body-xs", md: "body-sm"}}}>Lv.{char.level}</Typography> : <></>}
            </Box>
          </ListItem>
          {index !== characters.length - 1 ? <ListDivider/> : null}
        </div>;
      })}
    </List>
  </Card>;
});

export default App;
