// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBd4GhMf3TfTRzig0TzDicp5bpWyEjHFLQ",
    authDomain: "maple-guild-insight.firebaseapp.com",
    databaseURL: "https://maple-guild-insight-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "maple-guild-insight",
    storageBucket: "maple-guild-insight.appspot.com",
    messagingSenderId: "908876641142",
    appId: "1:908876641142:web:b9f584b3602d8c21230057",
    measurementId: "G-G1FD6JZXGP"
};

// Initialize Firebase
export function initializeFirebase() {
    const firebaseApp = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(firebaseApp);
}