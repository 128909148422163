import {worldName} from "../res/consts/world";

export async function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

// /**
//  * @param {Object} obj
//  * @returns {string}
//  */
// export function serialize(obj: Record<string, unknown>): string {
//     return JSON.stringify(obj);
// }
//
// /**
//  * @param {string} serialized
//  * @param {function} theClass
//  * @returns {*}
//  */
// export function deserialize<T>(serialized: string, theClass: new () => T): T {
//     const instance = new theClass();
//     const parsed = JSON.parse(serialized);
//     Object.assign(instance, parsed);
//     return instance;
// }

/**
 * @param {string[]} characterNames
 * @returns {number}
 */
export function getMembersHash(characterNames: string[] = []): number {
  return characterNames.toSorted((a, b) => a.localeCompare(b)).join(" ").hashCode();
}

declare global {
  interface String {
    hashCode(): number;
  }
}
String.prototype.hashCode = function (this: string): number {
  return cyrb53(this);
};

declare global {
  interface Date {
    addDays(days: number): number;
  }
}

Date.prototype.addDays = function (days: number): number {
  if (!days) return 123;
  let date = this;
  date.setDate(date.getDate() + days);

  return 123;
};

/**
 * @param {string} str
 * @param {?number} seed
 * @returns {number}
 */
function cyrb53(str: string, seed: number = 0): number {
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch: number; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

const worldKrToEnMap: Record<worldName, string> = {
  "아케인": "arcane",
  "오로라": "aurora",
  "버닝": "b1",
  "버닝2": "b2",
  "버닝3": "b3",
  "베라": "bera",
  "크로아": "croa",
  "엘리시움": "elysium",
  "이노시스": "inosis",
  "루나": "luna",
  "노바": "nova",
  "리부트": "r1",
  "리부트2": "r2",
  "레드": "red",
  "스카니아": "scania",
  "유니온": "union",
  "제니스": "zenith",
};

export function worldToEn(world: worldName): string {
  return worldKrToEnMap[world] ?? "default";
}

/**
 * 넥슨 API의 date 파라미터에 사용할 수 있는 최신 날짜값.
 *
 * 오전 8시 30분부터 오늘의 랭킹 정보를 조회할 수 있기 때문에,
 * 안전하게 현재가 9시 이전일 경우 어제 날짜를, 9시 이후일 경우 오늘 날짜를 반환
 */
export function getLatestDateParam(dateOffset: number = 0): string {
  const date: Date = new Date();
  if (date.getHours() < 9) {
    date.setDate(date.getDate() - 1);
  }
  date.setDate(date.getDate() + dateOffset);
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
}

export function setSessionStorage(): void {
  const cache: Record<string, string> = {
    "건축기사종건": "건축기사종건",
    "천사순자": "천사순자",
    "복델라": "복치",
    "내손에K5": "내손에K5",
    "UngEeee": "UngEeee",
    "내최애캐릭터": "내최애캐릭터",
    "뿌리깊은달": "용가리아시나",
    "육백인": "십일인",
    "너구리신짜오": "용가리아시나",
    "19720801": "19720801",
    "패이즈": "패이즈",
    "미련남은곰": "미련남은곰",
    "알파힐러": "알파진욱",
    "도도돌돌이이": "검마돌이표도",
    "Ranmenmiga": "Ranmenmiga",
    "너기엽네": "qqqOp",
    "드브므드브드": "모리다케하시",
    "시그너스솜2": "플위솜2",
    "민원기둥": "민원기둥",
    "손발꼼짝마": "손발꼼짝마",
    "페어턴리": "달러1400",
    "rudals0314": "null",
    "티보쉬": "티보띠",
    "윈브이의크링": "210301",
    "알파N버": "알파진욱",
    "교화청": "교화청",
    "세메닉스": "금색오닉스",
    "알파폴암": "알파진욱",
    "JHHHHHHD": "JHHHHHHD",
    "AlphaWhunter": "알파진욱",
    "소마터의크링": "210301",
    "알파파워힐러": "알파진욱",
    "AlphaDemonx": "알파진욱",
    "부서진시간": "부서진시간",
    "석주른": "석푸른",
    "신인균": "신인균",
    "복치델라": "복치",
    "이구역퉁퉁이": "이구역퉁퉁이",
    "oi소매": "oi소매",
    "사이트앞장": "사이트앞장",
    "알파문월": "알파진욱",
    "나고야파와몬": "엘클엘페",
    "멱궐": "낚멓",
    "석푸른": "석푸른",
    "던질꾸당": "던질꾸당",
    "호야붕붕17호": "듀콩촁",
    "w만상w": "디치기선수",
    "포털서비스팀": "null",
    "밤샘작업남": "달러1400",
    "엉덩2ee": "엉덩2ee",
    "두빵나무": "두빵나무",
    "김랑명": "김랑명",
    "솜2제로": "플위솜2",
    "z바위z": "z바위z",
    "짖만": "짖만",
    "꼭큄": "꼭큄",
    "쀼메메": "쀼메메",
    "스게아카가미": "용가리아시나",
    "언홀리인": "십일인",
    "AlphaHero": "알파진욱",
    "Tahri타리": "Tahri타리",
    "알파스토리": "알파진욱",
    "여고생스타킹": "누트누트",
    "쩡이컹크v": "null",
    "4po포": "4po포",
    "AlphaLuminus": "알파진욱",
    "숙취브레이커": "숙취브레이커",
    "시내아이재료": "신인균",
    "닉네임칸수좀": "링크667345",
    "아앙앙뚜우": "뿌우웅삥뽕",
    "강호겸": "강제류",
    "복치심비숍": "복치심비숍",
    "바람또주": "null",
    "뿌기뿌끼": "뚜꾸뚜삐",
    "개코코맘": "null",
    "김미진자": "김미진자",
    "찬란한찬혁": "찬란한찬혁",
    "정제근": "정제근",
    "Juphelios": "쯔슝",
    "수원물똥주먹": "수원물똥주먹",
    "금색오닉스": "금색오닉스",
    "메세프레이": "메세프레이",
    "비꼬삼": "비꼬삼",
    "S2에혀": "S2메세스",
    "앙쀼힛": "앙쀼힛",
    "알파진욱": "알파진욱",
    "임성면": "임성면",
    "듀콩촁": "듀콩촁",
    "플위솜2": "플위솜2",
    "복치": "복치",
    "썬코코몽": "연극",
    "이승환팔라딘": "이씨입니다",
    "슝슝데이": "null",
    "슈퍼파워레몬": "병란먹는아란",
    "허탈한찬혁": "찬란한찬혁",
    "핏빛아델": "핏빛아델",
    "링크667345": "링크667345",
    "무광데몽": "무광박쥐",
    "거역한": "null",
    "주용": "주용앙",
    "나순평": "신인균",
    "달콤레몬": "핏빛아델",
    "쭈므루": "쭈므루",
    "톡치면운다": "톡치면운다",
    "선물좀주셈": "듀콩촁",
    "꿔리": "기본기충실",
    "복치파인더": "복치",
    "AlphaKaiser": "알파진욱",
    "카인조음": "히요렌",
    "youknow1": "세리밍귀여워",
    "제로부터성녀": "내최애캐릭터",
    "뉴온링크600": "검마돌이표도",
    "기준에충실": "기본기충실",
    "용가리아시나": "용가리아시나",
    "린이누나": "린이누나",
    "두나무빵": "두빵나무",
    "모자롤": "모자롤",
    "기본기충실": "기본기충실",
    "난쏘고튀어": "난쏘고튀어",
    "뉴온링크1400": "검마돌이표도",
    "복치호랭": "복치",
    "아이쁘돌": "아이쁘돌",
    "Clisson": "르바헨",
    "o카무사리": "o카무사리",
    "우주스나": "광메부익",
    "복치의아이": "연극",
    "메르는여메르": "복치",
    "후롭롱": "후롭롱",
    "삼십인": "십일인",
    "계곡아델": "계곡아델",
    "v시노아뵤": "null",
    "아싸라마이코": "교화청",
    "무광박쥐": "무광박쥐",
    "난폭슈": "난폭슈",
    "qqqOp": "qqqOp",
    "리은월만찬": "만찬ll",
    "알파오닉스": "알파진욱",
    "쿠끼섭": "쿠끼섭",
    "데벤캔따개": "김랑명",
    "찰리Co": "달러1400",
    "풀문짱": "풀문짱",
    "신정동이광수": "신정동이광수",
    "NA복치": "NA복치",
    "오창워니": "오창워니",
    "알파Z로": "알파진욱",
    "메소폭팔섀도": "메소폭팔섀도",
    "은상이월": "은상이월",
    "YK0229": "YK0229",
    "06월18일휴무": "06월18일휴무",
    "메데김랑": "김랑명",
    "십일인": "십일인",
    "Soomi": "붐바스틱",
    "히요렌": "히요렌",
    "스페셜표유": "십일인",
    "주효담": "주효담",
    "방구차방구": "방구차방구",
    "쯔슝": "쯔슝",
    "연극": "연극",
    "만찬ll": "만찬ll",
    "덤불독": "덤불독",
    "호시노복치": "복치",
    "나무두빵": "두빵나무",
    "걍키워보기": "링크667345",
    "메음이": "null",
    "좋밥임선비AA": "좋밥임선비AA",
    "s유린이": "s유린이",
    "복치시그셋째": "복치",
    "월말특집": "월말특집",
    "제대언제하노": "고도모노히",
    "MerciBeatriz": "용가리아시나",
    "랑명핫도그": "김랑명",
    "이쁨슈": "난폭슈",
    "검마돌이표도": "검마돌이표도",
    "님혹시은월": "복치",
    "두손폴암잡고": "내손에K5",
    "뽀득도적": "뽀득도적",
    "알파바퍼": "알파진욱",
    "부룩밟": "부룩밟",
    "아잉라라등장": "아잉라라등장",
    "두메닉스": "금색오닉스",
    "곰베어카즈하": "곰베어카즈하",
    "디어유벨벳": "디어유벨벳",
    "퓽랑앙": "s유린이",
    "알파실피디아": "알파진욱",
    "빈키스승": "빈키여제",
    "쯔슝용기사": "쯔슝",
    "시원한사냥31": "링크667345",
  };
  for (const key in cache) {
    if (cache[key] !== "null") {
      sessionStorage.setItem(key, cache[key]);
    }
  }
}