export default class Character {
  name: string;
  guildName?: string;
  worldName?: string;
  imageUrl?: string;
  level?: number;
  className?: string;

  constructor({name, guildName, worldName, imageUrl, level, className}: {
    name: string;
    guildName?: string;
    worldName?: string;
    imageUrl?: string;
    level?: number;
    className?: string;
  }) {
    this.name = name;
    this.guildName = guildName;
    this.worldName = worldName;
    this.imageUrl = imageUrl;
    this.level = level;
    this.className = className;
  }

  test(): void {
    console.log("Yay!");
  }
}
