import RateLimiter from "../../../utils/rate_limiter";
import {apiKey, MAPLE_API_BASE_URL} from "../../../res/consts/common";
import {ResponseJson} from "../../../domain/model/response_json";

const rateLimiter = new RateLimiter({interval: 10, waitForFinish:false});

export async function getNexon<T extends ResponseJson>({url, query}: {
  url: string,
  query?: Record<string, string | number>,
}): Promise<T> {
  try {
    // @ts-ignore
    const paramsStr = new URLSearchParams(query).toString();
    const destUrl = `${MAPLE_API_BASE_URL}${url}${paramsStr ? `?${paramsStr}` : ""}`;
    const res = await rateLimiter.enqueue(() =>
      fetch(destUrl, {
        headers: {
          "accept": "application/json",
          "x-nxopen-api-key": apiKey,
        }
      }));
    if (res.status !== 200) {
      throw new Error(`Error while getting url "${url}": ${JSON.stringify(await res.json())}`);
    }
    return await res.json() as T;
  } catch (e) {
    throw e;
  } finally {
    // @ts-ignore
    // console.log(`took ${end - start}ms and ${localTries} tries to process ${url} with query: ${JSON.stringify(query)}`);
  }
}