import React, {useCallback, useState} from "react";
import {Box, Button, Chip, DialogTitle, Input, Modal, ModalDialog, Stack, Typography} from "@mui/joy";
import {worldName} from "../res/consts/world";
import {getGuildWorlds} from "../domain/repository/guild_repository";
import WorldIcon from "./world_icon";

type FoundWorlds = worldName[] | null | undefined;

function SearchGuildDialog(props: {
  children: any,
  onSelectGuild?: (guild: string, world: worldName) => void,
}): React.JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const openDialog = useCallback(() => setOpen(true), []);
  const closeDialog = useCallback(() => setOpen(false), []);
  const [searchedGuild, setSearchedGuild] = useState<string | undefined>();

  const [foundWorlds, setFoundWorlds] = useState<FoundWorlds>(undefined);
  const onSelectWorld = useCallback((world: worldName) => {
    if (!searchedGuild) {
      console.error("no guild specified");
      throw new Error("no guild specified");
    }
    setOpen(false);
    props.onSelectGuild?.(searchedGuild, world);
  }, [searchedGuild]);

  return <>
    <div style={{display: "contents"}} onClick={openDialog}>
      {props.children}
    </div>
    <Modal open={open} onClose={closeDialog}>
      <ModalDialog layout="top" sx={{width: 1, maxWidth: "400px"}}>
        <DialogTitle>길드 검색</DialogTitle>
        <form onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          if (typeof formJson["guild"] !== "string") {
            return;
          }

          const guild = formJson["guild"];
          setIsLoading(true);
          setSearchedGuild(guild);
          setFoundWorlds(await getGuildWorlds(guild));
          setIsLoading(false);
        }}>
          <Stack spacing={2}>
            <Input name="guild"
                   defaultValue={searchedGuild ?? ""}
                   disabled={isLoading}
                   autoComplete="off"
                   autoFocus/>
            <FoundWorldsSection guild={searchedGuild}
                                isLoading={isLoading}
                                foundWorlds={foundWorlds}
                                onClickWorld={onSelectWorld}/>
            <Button loading={isLoading} type="submit">검색</Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  </>;
}

const FoundWorldsSection = React.memo(
  (props: {
    guild?: string,
    isLoading: boolean,
    foundWorlds?: FoundWorlds,
    onClickWorld?: (world: worldName) => void,
  }): React.JSX.Element => {
    if (!props.guild || props.foundWorlds === undefined) {
      return <></>;
    } else if (props.foundWorlds === null) {
      return (
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Typography level="body-sm">검색 중 오류가 발생했습니다.<br/>다시 시도해보세요.</Typography>
        </Box>
      );
    } else if (props.foundWorlds.length === 0) {
      return (
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Typography level="body-sm">검색 결과가 없습니다.</Typography>
        </Box>
      );
    }

    return <Box sx={{
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: 1,
    }}>{
      props.foundWorlds.map(value =>
        <Chip variant="outlined"
              size="lg"
              disabled={props.isLoading}
              startDecorator={<WorldIcon world={value}/>}
              onClick={() => props.onClickWorld?.(value)}
              key={value}>
          <Typography level="body-sm">
            <Typography fontWeight="sm">{value}</Typography>
            &nbsp;
            <Typography fontWeight="lg">{props.guild}</Typography>
          </Typography>
        </Chip>
      )
    }</Box>;
  });

export default React.memo(SearchGuildDialog);